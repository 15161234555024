import styled, { themeGet, device } from "@theme/utils";

export const FollowUsArea = styled.div`
    margin-top: 60px;
`
export const SectionTitle = styled.div`

`
export const Title = styled.h3`

`
export const FollowUsBox = styled.div`
    margin-bottom: 60px;
`